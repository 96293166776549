import axios from 'axios';
import utils from './Utils';

var IS_SESSION_DUPLICATED_ALERT = false;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;   // PROD
axios.interceptors.request.use(
  function (response) {
    let loginId = utils.auth.getUserId();
    if (utils.string.isNotBlank(loginId)) {
      let headerUserIdEl = document.getElementById("header-user-id");
      if (headerUserIdEl) {
        let headerUserId = headerUserIdEl.innerText;
        if (utils.string.isNotBlank(headerUserId)) {
          if (loginId !== headerUserId) {
            window.location.reload();
          }
        }
      }
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const APIVersions = {
  superAdmin: {
    users: {version: 'v1'},
    projects: {version: 'v1'},
    bills: {version: 'v1'},
    qna: {version: 'v1'},
    dashboard: {version: 'v1'}
  },
  user: {
    users: {version: 'v1'},
    projects: {version: 'v1'},
    bills: {version: 'v1'}
  },
  projects: {
    version: 'v1',
    users: {version: 'v1'},
    invite: {version: 'v1'},
    bill: {version: 'v1'}
  },
  licenses: {
    version: 'v1'
  },
  health: {
    version: 'v1'
  },
  policy: {
    version: 'v1'
  },
  login: {
    version: 'v1'
  }
}

const checkNewToken = (response) => {
  if (response.headers.newtoken) {
    utils.auth.setToken(response.headers.newtoken);
  }
  if (response.status === 401 || response.data.status === 401) {
    if (response.data.body) {
      // 중복 로그인 처리
      let isSessionDuplicated = response.data.body.isSessionDuplicated;
      let message = response.data.message;
      if (isSessionDuplicated === true) {
        if (IS_SESSION_DUPLICATED_ALERT == false) {
          alert(message);
        }
        IS_SESSION_DUPLICATED_ALERT = true;
        utils.auth.removeToken();
        window.location.href = "/console/login";
        return;
      }
    }

    APIsLists.logout((data) => {
      window.location.href = "/console/login";
      return;
    });
    return;
  }
}

/**
 * 응답 없는 에러에 대한 응답 처리
 * @param error
 * @returns {{success: boolean, message: string, status: number}}
 */
const errorResult = (error) => {
  console.log("error",error);
  let resultData = {"status": -1, "success": false, "message": String(error)};
  if (error.response && utils.string.isJsonObject(error.response.data) && error.response.data.status) {
    resultData = error.response.data;
  } else if (error.response && !utils.string.isJsonObject(error.response.data) && error.response.status) {
    resultData = {"status": error.response.status, "success": false, "message": error.response.statusText};
  }
  if(resultData.body !== null && resultData.body !== undefined && resultData.body.passwordChangeCheck !== undefined){
    if(resultData.body.passwordChangeCheck === "false"){
      window.location.href="/console/pwChangeCheck";
    }
  }
  console.log("errorResult resultData: ", resultData, ", error: ", error)
  return resultData;
}
const httpRequest = {
  get: ({url, params, headers = {}}, callback) => {
    Object.assign(headers, {
      authorization: `Bearer ${utils.auth.getToken()}`
    });

    axios.get(url, {
      params,
      headers
    }).then(response => {
      checkNewToken(response);
      callback(response.data, response)
    }).catch(error => {
      console.log('get error url: ', url, ', error: ', error, ', error.response: ', error.response);
      if (error.response) {
        checkNewToken(error.response);
        callback(errorResult(error), error.response)
      } else {
        callback(errorResult(error));
      }
    })
  },
  post: ({url, body, headers = {}, params}, callback) => {
    Object.assign(headers, {
      authorization: `Bearer ${utils.auth.getToken()}`
    });
    if (params) {
      const urlParams = new URLSearchParams();
      Object.keys(params).map((key) => {
        urlParams.append(key, params[key]);
      });
      body = urlParams;
    }

    axios.post(url, body, {
      headers
    }).then(response => {
      checkNewToken(response);
      callback(response.data, response);
    }).catch(error => {
      console.log('post error url: ', url, 'error: ', error);
      if (error.response) {
        checkNewToken(error.response)
        callback(errorResult(error), error.response)
      } else {
        callback(errorResult(error));
      }
    })
  },
  put: ({url, body, headers = {}, params}, callback) => {
    Object.assign(headers, {
      authorization: `Bearer ${utils.auth.getToken()}`
    });
    if (params) {
      const urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        urlParams.append(key, params[key]);
      });
      body = urlParams;
    }

    axios.post(url, body, {
      headers,
      params: {
        _method: 'put'
      }
    })
      .then(response => {
        checkNewToken(response);
        callback(response.data, response);
      }).catch(error => {
      console.log('put error url: ', url, 'error: ', error);
      if (error.response) {
        checkNewToken(error.response);
        callback(errorResult(error), error.response)
      } else {
        callback(errorResult(error));
      }
    })
  },
  delete: ({url, body, headers = {}, params}, callback) => {
    Object.assign(headers, {
      authorization: `Bearer ${utils.auth.getToken()}`
    });
    if (params) {
      const urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        urlParams.append(key, params[key]);
      });
      body = urlParams;
    }

    axios.post(url, body, {
      headers,
      params: {
        _method: 'delete'
      }
    })
      .then(response => {
        checkNewToken(response);
        callback(response.data, response);
      }).catch(error => {
      console.log('delete error url: ', url, 'error: ', error);
      if (error.response) {
        checkNewToken(error.response);
        callback(errorResult(error), error.response)
      } else {
        callback(errorResult(error));
      }
    })
  }
}

const APIsLists = {
  superAdmin: {
    getUsers(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.users.version}/admin/users`
      }), callback)
    },
    changeUserInfo(options, callback) {
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.users.version}/admin/users`
      }), callback)
    },
    deleteUser(options, callback) {
      const {userId} = options;
      if (!userId) return;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.users.version}/admin/users/${userId}`
      }), callback)
    },
    recoverUser(options, callback) {
      const {userId} = options;
      if (!userId) return;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.users.version}/admin/users/${userId}/recovery`
      }), callback)
    },
    deleteCompleteUser(options, callback) {
      const {userId} = options;
      if (!userId) return;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.users.version}/admin/users/${userId}/force`
      }), callback)
    },
    getProjects(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects`
      }), callback)
    },
    getUserProjects(options, callback) {
      const {userId} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/user/${userId}`
      }), callback)
    },
    changeProjectInfo(options, callback) {
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects`
      }), callback)
    },
    getProjectMembers(options, callback) {
      const {pid} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/users`
      }), callback)
    },
    changeProjectMemberRole(options, callback) {
      const {pid, userId, role} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/users/${userId}/role/${role}`
      }), callback)
    },
    changeProjectRoles(options, callback) {
      const {pid} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/users/roles`
      }), callback)
    },
    removeProjectMembers(options, callback) {
      const { pid, userIds } = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/users/${userIds}`
      }), callback)
    },
    inviteProjectMember(options, callback) {
      const { pid } = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/users/invites`
      }), callback)
    },
    getProjectLicenses(options, callback) {
      const {pid} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/licenses`
      }), callback)
    },
    createProjectLicense(options, callback) {
      const {pid} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/license`
      }), callback)
    },
    removeProjectLicense(options, callback) {
      const {pid, seq} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.projects.version}/admin/projects/${pid}/license/${seq}`
      }), callback)
    },
    getBillsList(options, callback) {
      const {billingMonth} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projectSubscriptionAll/bills/${billingMonth}`
      }), callback)
    },
    getSalesBillsList(options, callback) {
      const {year} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projectSales/bills/${year}`
      }), callback)
    },
    getBillDetail(options, callback) {
      const {pid, billId} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills/${billId}`
      }), callback)
    },
    createBill(options, callback) {
      const {pid, billingMonth} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills/create/${billingMonth}`
      }), callback)
    },
    recreateBill(options, callback) {
      const {pid, billingMonth} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills/recreate/${billingMonth}`
      }), callback)
    },
    createRangeBill(options, callback) {
      const {pid, licenseSeq} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills/createRange/${licenseSeq}`
      }), callback)
    },
    recreateRangeBill(options, callback) {
      const {pid, licenseSeq, billId} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills/recreateRange/${licenseSeq}/${billId}`
      }), callback)
    },
    billPaymentList(options, callback) {
      const {pid, billId} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/payment/bills/${billId}`
      }), callback)
    },
    payment(options, callback) {
      const {pid, billId} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/payment/bills/${billId}`
      }), callback)
    },
    paymentCancel(options, callback) {
      const {pid, billId} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/payment/bills/${billId}`
      }), callback)
    },
    getProjectBills(options, callback) {
      const {pid} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.bills.version}/admin/projects/${pid}/bills`
      }), callback)
    },
    getQnaList(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards`
      }), callback)
    },
    getQnaContent(options, callback) {
      const {pid, seq} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/${pid}/${seq}`,
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }), callback)
    },
    doneQnaContent(options, callback) {
      const {pid, seq} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/${pid}/${seq}/done`
      }), callback)
    },
    reOpenQnaContent(options, callback) {
      const {pid, seq} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/${pid}/${seq}/reopen`
      }), callback)
    },
    removeQnaContent(options, callback) {
      const {pid, seq} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/${pid}/${seq}`
      }), callback)
    },
    createQnaComment(options, callback) {
      const {pid, seq} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/comments/${pid}/${seq}`
      }), callback)
    },
    removeQnaComment(options, callback) {
      const {commentSeq} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/comments/${commentSeq}`
      }), callback)
    },
    updateQnaComment(options, callback) {
      const {commentSeq} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.qna.version}/admin/boards/comments/${commentSeq}`
      }), callback)
    },
    getDashboardInfo(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/dashboard`
      }), callback)
    },
    createAPM(options, callback) {
      const { pid, recreate } = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/projects/${pid}/createAPM?recreate=${recreate}`
      }), callback)
    },
    removeAPM(options, callback) {
      const { pid } = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/projects/${pid}/removeAPM`
      }), callback)
    },
    getApmVersions(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/apm/versions`
      }), callback)
    },
    deployApmGroup(options, callback) {
      const { version, group, recreate } = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/apm/versions/${version}/group/${group}/${recreate}`
      }), callback)
    },
    deployApmPids(options, callback) {
      const { version, pids, recreate } = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/apm/versions/${version}/pids/${pids}/${recreate}`
      }), callback)
    },
    getEvents(options, callback) {
      const { start, end } = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/events/${start}/${end}`
      }), callback)
    },
    getAppInfo(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/info`
      }), callback)
    },
    getUseStatus(options, callback) {
      const { pid } = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/projects/${pid}/useStatus`
      }), callback)
    },
    getMessageSetting(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/messageSources`
      }), callback)
    },
    editMessage(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.superAdmin.dashboard.version}/admin/messageSource`
      }), callback)
    },
    clearCache(options, callback) {
      const { cacheName } = options;
      return httpRequest.get(
        Object.assign(options, {
          url: `/api/${APIVersions.superAdmin.dashboard.version}/cache/server/clear/${cacheName}`
        }),
        callback
      );
    }
  },
  user: {
    createUser(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users`
      }), callback)
    },
    updateUser(options, callback) {
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users`
      }), callback);
    },
    deleteUser(options, callback) {
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/cancel`
      }), callback);
    },
    changePassword(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/change/password`
      }), callback);
    },
    findId(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/find/id`
      }), callback);
    },
    findPassword(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/find/password`
      }), callback);
    },
    resetPassword(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/reset/password`
      }), callback);
    },
    getUser(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/info`
      }), callback);
    },
    getUserImg(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/img`
      }), callback);
    },
    checkOverlap(options, callback) {
      const { userId } = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/overlapId/${userId}`
      }), callback);
    },
    sendCertNumberMail(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/send/cert/number/email`
      }), callback);
    },
    checkCertNumberMail(options, callback) {
      const { token } = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/check/cert/number/email/${token}`
      }), callback);
    },
    excludeEmails(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/exclude/emails`
      }), callback)
    },
    extendsChangePassword(options, callback){
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users/extends/passwordChangeCheck`
      }), callback)
    },
    getNotis(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/notis`
      }), callback);
    },
    tokenUsageCheck(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.user.users.version}/users`
      }), callback)
    },
  },
  project: {
    createProjects(options, callback) {
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects`
      }), callback);
    },
    updateProjects(options, callback) {
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects`
      }), callback);
    },
    getProjects(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects`
      }), callback);
    },
    getProjectInfo(options, callback) {
      const {pid} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${pid}`
      }), callback);
    },
    deleteProject(options, callback) {
      const {projectId} = options;
      if (!projectId) return;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${projectId}`
      }), callback);
    },
    getProjectMembers(options, callback) {
      const {projectId} = options;
      if (!projectId) return;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${projectId}/users`
      }), callback);
    },
    deleteProjectMember(options, callback) {
      const {projectId, userId} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${projectId}/users/${userId}`
      }), callback);
    },
    changeProjectMemberRole(options, callback) {
      const {pid, userId, role} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${pid}/users/${userId}/${role}`
      }), callback);
    },
    inviteProjectMember(options, callback) {
      const {pid} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${pid}/users/invites`
      }), callback);
    },
    inviteUpdateProjectMember(options, callback) {
      const {pid} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${pid}/users/invites`
      }), callback);
    },
    getInvites(options, callback) {
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/invites`
      }), callback);
    },
    getProjectInvites(options, callback) {
      const {pid} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/projects/${pid}/users/invites`
      }), callback);
    },
    checkUserByInviteToken(options, callback) {
      const {inviteToken} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/invite/${inviteToken}`
      }), callback);
    },
    acceptInvite(options, callback) {
      const {pid} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/projects/${pid}/users/invites/accept`
      }), callback);
    },
    rejectInvite(options, callback) {
      const {pid} = options;
      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/projects/${pid}/users/invites/reject`
      }), callback);
    },
    getCancelInvite(options, callback) {
      const {pid} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.invite.version}/projects/${pid}/users/invites`
      }), callback);
    },
    getProjectBills(options, callback) {
      const {pid, limit} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/bills${utils.string.isNotBlank(limit)?'?limit=' + limit:''}`
      }), callback);
    },
    getProjectBillDetail(options, callback) {
      const {pid, billId} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/bills/${billId}`
      }), callback)
    },
    payment(options, callback) {
      const {pid, billId} = options;
      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/payment/bills/${billId}`
      }), callback)
    },
    getProjectBillPays(options, callback) {
      const {pid, billId} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/bills/${billId}/pays`
      }), callback);
    },
    getMembers(options, callback) {
      const {pid} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${pid}/users`
      }), callback);
    },
    excludeMember(options, callback) {
      const {pid, userId} = options;
      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.users.version}/projects/${pid}/users/${userId}`
      }), callback);
    },
    getSystemUsage(options, callback) {
      const {pid, startDate, endDate} = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${pid}/resource/${startDate}/${endDate}`
      }), callback)
    },
    subscriptionRegister(options, callback) {
      const { pid } = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/pay/card/add/step1`
      }), callback)
    },
    getCardInfo(options, callback) {
      const { pid } = options;
      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.bill.version}/projects/${pid}/pay/card`
      }), callback)
    },
    getProjectPoc(options, callback) {
      const {pid} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${pid}/poc`
      }), callback);
    },
    getProjectLicense(options, callback) {
      const {pid} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${pid}/license`
      }), callback);
    },
    subscriptionStop(options, callback) {
      const {pid, endMillis} = options;

      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/projects/${pid}/subscriptionStop/${endMillis}`
      }), callback);
    },
    getOpenmaruServerHost(options, callback) {
      const {pid} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/admin/projects/${pid}/openmaruHost?timestamp=${Date.now()}`
      }), callback);
    },
  },
  login(options, callback) {
    return httpRequest.post(Object.assign(options, {
      url: `/api/v1/login`
    }), callback);
  },
  logout(callback) {
    httpRequest.post(Object.assign({}, {
      url: `/api/v1/logout`
    }), (data, response) => {
      if (data.status === 200) {
        utils.auth.removeToken();
        callback(data);
      }
      // test.test();
      // let history = useHistory();

      // history.push('/login');

      // history.push('/login');
      // 속도가 느림 --> history.push로 변경
      // window.location.href = "/console"
    })
  },
  checkIdPass(options, callback){
    return httpRequest.post(Object.assign(options, {
      url: `/api/v1/checkPass`
    }), callback);
  },
  checkPassAndUser(options, callback){
    return httpRequest.post(Object.assign(options, {
      url: `/api/v1/checkPassAndUser`
    }), callback);
  },
  loginHistory(options, callback) {
    const {start, end} = options;
    return httpRequest.get(Object.assign(options, {
      url: `/api/${APIVersions.login.version}/login/history/${start}/${end}`
    }), callback);
  },
  regOpt(options, callback) {
    return httpRequest.put(Object.assign(options, {
      url: `/api/v1/otp`
    }), callback);
  },
  authOpt(options, callback) {
    return httpRequest.post(Object.assign(options, {
      url: `/api/v1/otp`
    }), callback);
  },
  board: {
    getBoards(options, callback) {
      const {pid} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/boards/${pid}`
      }), callback);
    },
    getBoardContent(options, callback) {
      const {pid, seq} = options;

      return httpRequest.get(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/boards/${pid}/${seq}?timestamp=${Date.now()}`
      }), callback);
    },
    write(options, callback) {
      const {pid} = options;

      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/boards/${pid}`
      }), callback);
    },
    edit(options, callback) {
      const {pid, seq} = options;

      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/boards/${pid}/${seq}`
      }), callback);
    },
    delete(options, callback) {
      const {pid, seq} = options;

      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/boards/${pid}/${seq}`
      }), callback);
    },
    createBoardComment(options, callback) {
      const {pid, seq} = options;

      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/comments/${pid}/${seq}`
      }), callback);
    },
    updateBoardComment(options, callback) {
      const {pid, commentSeq} = options;

      return httpRequest.put(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/comments/${pid}/${commentSeq}`
      }), callback);
    },
    removeBoardComment(options, callback) {
      const {pid, commentSeq} = options;

      return httpRequest.delete(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/comments/${pid}/${commentSeq}`
      }), callback);
    },
    done(options, callback) {
      const {pid, seq} = options;

      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/comments/${pid}/${seq}/done`
      }), callback);
    },
    reOpen(options, callback) {
      const {pid, seq} = options;

      return httpRequest.post(Object.assign(options, {
        url: `/api/${APIVersions.projects.version}/comments/${pid}/${seq}/reopen`
      }), callback);
    },
  },
  checkApmHealth(options, callback) {
    const {pids, isExternalBrowserApi} = options;
    return httpRequest.get(Object.assign(options, {
      url: `/api/${APIVersions.health.version}/apm/health?pids=${pids}&isExternalBrowserApi=${isExternalBrowserApi?isExternalBrowserApi:false}`
    }), callback);
  },
  subscriptionPolicy(options, callback) {
    return httpRequest.get(Object.assign(options, {
      url: `/api/${APIVersions.policy.version}/info/subscription/policy`
    }), callback);
  }
}

export default APIsLists;
